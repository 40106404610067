<template>
  <el-card style="margin-bottom: 20px" v-loading="loading">
    <!-- HEADER -->
    <h3 slot="header">
      {{ setting.label || name }}
      <Tooltip :label="setting.label || name" :content="setting.detail" />
    </h3>

    <!-- NOT AUTHORISED -->
    <p class="info-text" v-if="!isAuthorized">
      Only users with "supervisor" module access can configure this setting.
    </p>

    <!-- AUTHORISED -->
    <div v-if="isAuthorized">
      <!-- INFO -->
      <p class="info-text">
        This limit only applies to auto assignments. You can configure agents if they belong to at
        least one department that you are a part of. The list of agents and departments is retrieved
        from the system authentication data and not manually configured here.

        <br /><br />
        Your departments are:
        <el-tag
          v-for="department in this.getUserAuthentication?.departments"
          :key="department"
          type="primary"
          style="margin: 3px 3px 3px 0"
          size="small"
        >
          {{ department }}
        </el-tag>
      </p>

      <!-- RETRY BUTTON -->
      <el-button
        v-if="error"
        @click="retryQuery"
        type="primary"
        :disabled="this.loading"
        size="mini"
        style="margin-bottom: 10px"
        >Retry fetching data</el-button
      >

      <!-- TABLE -->
      <el-table :data="tableData" style="width: 90%" max-height="500" stripe border>
        <!-- EMAIL / AGENT ID -->
        <el-table-column prop="agentId" label="Email" width="250" />

        <!-- DEPARTMENTS -->
        <el-table-column property="department" label="Departments">
          <template slot-scope="scope">
            <i
              v-if="scope.row.departments.length === 0"
              style="font-size: 9px"
              class="has-text-dark"
            >
              No department assigned.
            </i>
            <el-tag
              v-for="department in scope.row.departments.filter((item) => item)"
              :key="`${scope.row.email}-${department}`"
              type="primary"
              style="margin: 3px 3px 3px 0"
            >
              {{ department }}
            </el-tag>
          </template>
        </el-table-column>

        <!-- CHAT LIMIT -->
        <el-table-column prop="limit" label="Chat limit" width="200">
          <template slot-scope="scope">
            <div v-if="scope.row.limit">
              <!-- USE DEFAULT LIMIT -->
              <el-checkbox v-model="scope.row.limit.useDefaultLimit" label="Use default" />
              <!-- INPUT TEXT -->
              <el-input-number
                v-model="scope.row.limit.count"
                :min="0"
                :max="99"
                size="mini"
                :disabled="scope.row.limit.useDefaultLimit"
              />
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </el-card>
</template>

<script>
import Tooltip from "../../Tooltip.vue";
import gql from "graphql-tag";
import { checkRoles, getPermittedRoles } from "@/helperMethods/auth";
import { mapGetters } from "vuex";

export default {
  name: "HandoverAgentChatLimitPerUser",
  props: {
    setting: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    modules: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  components: {
    Tooltip,
  },
  data() {
    return {
      loading: true,
      defaultChatLimit: this.value["chat_limit"] || 1,
      tableData: [],
      error: false,
    };
  },
  computed: {
    ...mapGetters(["getUserAuthentication"]),
    isAuthorized() {
      const roles = this.getUserAuthentication?.roles || [];
      const allowedRoles = getPermittedRoles("supervisor");
      const isAuthorized = checkRoles(allowedRoles);
      const adminRole = roles && roles.includes("admin");

      return isAuthorized || adminRole;
    },
    existingSetting() {
      return _.cloneDeep(this.value?.[this.name] || []);
    },
  },
  mounted() {
    if (!_.isArray(this.value?.[this.name]))
      this.$set(this.value, this.name, this.setting.default || []);
  },
  methods: {
    retryQuery() {
      this.$apollo.queries.getDashboardUsers.refetch();
    },
  },
  watch: {
    tableData: {
      handler(newVal) {
        try {
          const settingToBeUpdated = _.cloneDeep(this.existingSetting);

          _.each(newVal, ({ agentId, limit = {}, authType }) => {
            const { count, useDefaultLimit } = limit;

            const settingForThisAgent = {
              agentId,
              authType,
              limit: useDefaultLimit ? this.defaultChatLimit : count,
            };

            // need the index to maintain the array order and prevent unintended changes to the JSON preview for other department users
            const foundIndex = _.findIndex(
              settingToBeUpdated,
              (setting) =>
                typeof agentId === "string" &&
                typeof agentId === "string" &&
                agentId.toLowerCase() === setting.agentId.toLowerCase()
            );
            const previousData = foundIndex > -1 ? settingToBeUpdated[foundIndex] : null;

            // Exclude agents using default chat limit from being stored in the setting JSON
            if (!previousData && useDefaultLimit) return; // continue to next iteration
            else if (previousData && useDefaultLimit) {
              settingToBeUpdated.splice(foundIndex, 1); // remove from setting
            } else if (previousData) {
              settingToBeUpdated[foundIndex] = settingForThisAgent;
            } else if (!previousData) {
              settingToBeUpdated.push(settingForThisAgent);
            }
          });

          this.value[this.name] = settingToBeUpdated;
        } catch (error) {
          console.error(error);
          this.error = true;
          this.$notify.error({
            title: "Error",
            position: "bottom-right",
            message: "Failed to update Chat Limit per Agent table data. Please retry again.",
          });
        }
      },
      deep: true,
    },
  },
  apollo: {
    getDashboardUsers: {
      query: gql`
        query {
          agentAPI {
            dashboardUsers {
              email
              authType
              departments
            }
          }
        }
      `,
      fetchPolicy: "network-only",
      update(data) {
        try {
          const parsedDashboardUsers = _.get(data, "agentAPI.dashboardUsers", []);

          const myDepartments = Array.isArray(this.getUserAuthentication.departments)
            ? this.getUserAuthentication.departments
            : [];
          const newSetting = _.map(parsedDashboardUsers, (user) => {
            const userDepartments = Array.isArray(user.departments) ? user.departments : [];

            if (_.intersection(userDepartments, myDepartments).length > 0) {
              const currentAgentSetting = _.find(
                this.existingSetting,
                (setting) => setting.agentId === user.email
              );

              return {
                agentId: user.email,
                authType: user.authType,
                departments: user.departments,
                limit: {
                  useDefaultLimit: _.isEmpty(currentAgentSetting),
                  count: _.isInteger(currentAgentSetting?.limit)
                    ? currentAgentSetting.limit
                    : this.defaultChatLimit,
                },
              };
            }
          }).filter(Boolean);

          this.tableData = newSetting;
        } catch (error) {
          console.error(error);
          this.$notify.error({
            title: "Error",
            position: "bottom-right",
            message: "Failed to process Chat Limit per Agent data. Please click the retry button.",
          });
          this.error = true;
        } finally {
          this.loading = false;
          this.error = false;
        }
      },
      error(error) {
        console.error(error);
        this.$notify.error({
          title: "Error",
          position: "bottom-right",
          message: "Failed to load Chat Limit per Agent data. Please click the retry button.",
        });
        this.loading = false;
        this.error = true;
      },
    },
  },
};
</script>

<style>
.box-card {
  padding: 20px;
}
.box-container {
  margin-bottom: 20px;
}
.hint {
  font-size: 12px;
  color: #888;
  margin-top: 5px;
  line-height: 20px;
}
.info-text {
  font-size: 14px;
  color: grey;
  margin-bottom: 20px;
}
</style>
