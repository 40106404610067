<template>
  <div class="livechat-container">
    <div class="livechat-container--top">
      <MonitorChatFilters
        :selectedChat="selectedChat"
        @setHasNoMoreChats="hasNoMoreChats = $event"
        @actionTriggered="onActionTriggered"
      />
    </div>

    <div class="livechat-container--left">
      <div class="chat-list--header">
        <span>
          {{ monitorChatsCount }} Monitor Chats
          <el-tooltip
            effect="dark"
            content="These conversation are not updated in real-time"
            placement="right"
            style="margin-left: 4px"
          >
            <i class="has-text-grey el-icon-question" style="margin-left: 0.5em" />
          </el-tooltip>
        </span>
      </div>

      <div class="chat-list--wrapper">
        <div
          class="chat-list--main"
          v-loading="fetchMonitorChatLoading"
          element-loading-text="Loading chats"
        >
          <el-row
            v-if="!monitorChatsArray || monitorChatsArray.length === 0"
            style="text-align: center; position: relative"
          >
            <h4>There are no conversation yet.</h4>
          </el-row>
          <el-row
            v-for="(chat, index) in monitorChatsArray"
            :key="`${chat.PartitionKey}_${chat.RowKey}-${index}`"
            class="item"
            effect="dark"
            placement="right"
            @click.native="
              selectChat(chat.PartitionKey, chat.RowKey, chat.interactions?.length || 0)
            "
            style="margin-bottom: 4px"
            :class="
              getChatClass(index, chat, selectedMonitorChatRowKey, selectedMonitorChatPartitionKey)
            "
          >
            <el-row type="flex" style="margin-bottom: 0">
              <el-col :span="24">
                <el-row type="flex">
                  <ChatAvatar :chat="chat" style="display: inline-block" />
                  <el-col
                    style="
                      overflow: hidden;
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                    "
                  >
                    <div class="chat-name">
                      <span style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
                        {{ getChatName(chat) }}
                      </span>
                    </div>
                    <el-row style="margin-top: 4px" v-if="getTagName(chat.stateVariables)">
                      <el-tag :type="tagType(getTagName(chat.stateVariables))" size="mini">{{
                        getTagName(chat.stateVariables)
                      }}</el-tag>
                    </el-row>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
            <el-row style="margin-bottom: 0" v-if="get(chat, 'agents.length') > 0">
              <el-col
                :span="24"
                class="has-text-dark"
                style="font-size: 0.6em; margin-top: 5px; white-space: normal; font-weight: 400"
              >
                <i>Attended by {{ chat.agents.join(", ") }}</i>
              </el-col>
            </el-row>
          </el-row>
          <el-row>
            <div
              v-loading="showMoreIsLoading"
              element-loading-spinner="el-icon-loading"
              v-if="monitorChatsArray && monitorChatsArray.length > 0 && !hasNoMoreChats"
              class="prev-conversation"
              @click="fetchMoreMonitorChats"
            >
              - show more -
            </div>
          </el-row>
        </div>
      </div>
    </div>

    <div v-if="selectedChat" class="livechat-container--right">
      <ChatsInteractions
        ref="interactionsPanel"
        :chats-list="monitorChatsArray"
        :selected-chat="selectedChat"
        :selected-index.sync="selectedIndex"
        :selected-row-key.sync="selectedMonitorChatRowKey"
        :is-loading="$apollo.queries.monitorChats.loading"
        :closed.sync="closed"
      />

      <el-row v-if="$store.state.showAdvanced">
        <JSONEditor v-model="selectedChat" />
      </el-row>
    </div>
  </div>
</template>

<script>
import MonitorChatFilters from "@/components/LiveChat/monitorChats/MonitorChatFilters";
import ChatsInteractions from "@/components/ChatInteractions/Index";
import JSONEditor from "@/components/JSONEditor";
import ChatAvatar from "@/components/LiveChat/ChatAvatar";
import MonitorQuery from "@/graphql/LIVECHATS_GET_MONITOR.gql";
import { pushNotification } from "@/helperMethods/pushNotification";
import { getAvatar, getChatClass, getDisplayId, getPreview } from "@/helperMethods/livechat/util";
import { parseMonitorChats } from "@/helperMethods/livechat/parseMonitorChats";
import { getChatName, getTagName, tagType } from "@/filters/index";

import { mapGetters } from "vuex";
import _ from "lodash";
import { NIL as NIL_UUID } from "uuid";
import get from "lodash/get";
import notificationUnreadMessage from "../../../../static/mixkit-happy-bell-alert-601.wav";

export default {
  name: "MonitorChatsTab",
  components: {
    MonitorChatFilters,
    ChatsInteractions,
    JSONEditor,
    ChatAvatar,
  },
  data() {
    return {
      selectedIndex: 0,
      tncDisabled: !this.$store.state.modules.tnc.enabled,
      filterActiveUser: false,
      closed: false,
      hasNoMoreChats: false,
      showMoreIsLoading: false,
      intervalFetchMonitorChats: null,
      sessionId: "",
      monitorChatsLen: null,
    };
  },
  computed: {
    ...mapGetters([
      "selectedMonitorChatPartitionKey",
      "selectedMonitorChatRowKey",
      "monitorChatFilters",
      "fetchMonitorChatLoading",
      "monitorChatsArray",
      "userDepartment",
    ]),
    monitorChatsCount() {
      if (_.isEmpty(this.monitorChatsArray)) {
        return 0;
      }
      return this.monitorChatsArray.length;
    },
    selectedChat() {
      return this.monitorChatsArray.find(
        (chat) =>
          chat.PartitionKey === this.selectedMonitorChatPartitionKey &&
          chat.RowKey === this.selectedMonitorChatRowKey
      );
    },
    activeUsersToolTip() {
      let tooltipMessage = "Active users will filter chats with TNC modules and accepted the TNC";
      if (this.tncDisabled) {
        tooltipMessage = "TnC module is disabled, default to display all users";
      }
      return tooltipMessage;
    },
  },
  mounted() {
    const sessionId = this.$route.params.sessionId;
    if (sessionId) {
      this.sessionId = sessionId;
      const cloneMonitorChatFilters = _.cloneDeep(this.monitorChatFilters);
      cloneMonitorChatFilters.sessionId = sessionId;
      cloneMonitorChatFilters.messageLimit = 200;
      const isRestrictCrossDepartmentEnable =
        this.$store.state.modules.handover.restrictCrossDepartmentAccess;
      const departmentObj = _.get(this.$store, "state.department.departments", []);
      cloneMonitorChatFilters.departments = isRestrictCrossDepartmentEnable
        ? this.userDepartment?.map((v) =>
            _.chain(departmentObj).find(["name", v]).get("id", NIL_UUID).value()
          ) || []
        : [];
      this.$store.commit("UPDATE_MONITOR_LIVECHAT_FILTERS", cloneMonitorChatFilters);
    }
  },
  // watch monitorChatsArray change
  watch: {
    monitorChatsArray: {
      handler: function (newVal) {
        if (!_.get(this, "$store.state.modules.webchat.soundNotification")) {
          return;
        }
        if (newVal && newVal.length) {
          const unreadChats = newVal.filter((chat) => !chat.agents.length).length;
          if (this.monitorChatsLen == null) {
            this.monitorChatsLen = 0;
            return;
          }
          if (unreadChats > 0 && (!this.monitorChatsLen || unreadChats > this.monitorChatsLen)) {
            this.playSound();
            this.monitorChatsLen = unreadChats;
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    getAvatar,
    getDisplayId,
    getChatClass,
    getPreview,
    get,
    getChatName,
    getTagName,
    tagType,

    closeMobileChatView() {
      this.closed = false;
    },
    selectChat(partitionKey, rowKey, interactionsLength) {
      this.$store.commit("SELECT_MONITOR_CHAT", {
        partitionKey,
        rowKey,
        type: "monitor",
      });
      if (interactionsLength < 10) {
        this.onInitFetchInteraction();
      }
      // this.$store.commit("SET_SELECTED_CHAT_ID", chatId);
    },
    onInitFetchInteraction() {
      this.$nextTick(() => {
        this.$refs.interactionsPanel?.showMoreConversation(this.selectedChat, true);
      });
    },
    fetchMoreMonitorChats() {
      this.showMoreIsLoading = true;
      const cloneMonitorChatFilters = _.cloneDeep(this.monitorChatFilters);
      cloneMonitorChatFilters.limit = 20;
      cloneMonitorChatFilters.offset = this.monitorChatsArray.length;
      this.$store.commit("UPDATE_MONITOR_LIVECHAT_FILTERS", cloneMonitorChatFilters);
    },
    onActionTriggered() {
      const monitorLivechatIntervalFetchEnabled = _.get(
        this,
        "$store.state.modules.system.monitorLivechatIntervalFetch.enabled",
        true
      );

      if (monitorLivechatIntervalFetchEnabled) {
        this.resetIntervalFetchMonitorChats();
      }
    },
    fetchMonitorChats() {
      const isBusy = this.showMoreIsLoading || this.fetchMonitorChatLoading;

      if (!isBusy && !this.selectedChat) {
        const filter = _.cloneDeep(this.monitorChatFilters);
        filter.limit = this.monitorChatsArray.length < 20 ? 20 : this.monitorChatsArray.length;

        filter.offset = 0;
        this.$store.commit("SET_FETCH_MONITOR_CHAT_LOADING", true);
        this.$store.commit("FLUSH_MONITOR_CHATS_ARRAY");
        this.$store.commit("UPDATE_MONITOR_LIVECHAT_FILTERS", filter);

        if (this.$apollo.queries.monitorChats) {
          this.$apollo.queries.monitorChats.refresh();
        }
      }
    },
    resetIntervalFetchMonitorChats() {
      this.clearIntervalFetchMonitorChats();
      this.startIntervalFetchMonitorChats();
    },
    clearIntervalFetchMonitorChats() {
      if (this.intervalFetchMonitorChats) {
        clearInterval(this.intervalFetchMonitorChats);
      }
    },
    startIntervalFetchMonitorChats() {
      const intervalFetchInSeconds = _.get(
        this,
        "$store.state.modules.system.monitorLivechatIntervalFetch.interval",
        30
      );
      this.intervalFetchMonitorChats = setInterval(
        this.fetchMonitorChats,
        intervalFetchInSeconds * 1000 // in miliseconds
      );
    },
    playSound() {
      const audio = new Audio(notificationUnreadMessage);
      audio.play();
    },
  },
  beforeDestroy() {
    this.clearIntervalFetchMonitorChats();
  },
  apollo: {
    monitorChats: {
      query: MonitorQuery,
      variables() {
        return this.monitorChatFilters;
      },
      fetchPolicy: "network-only",
      update(result) {
        // console.log("Fetched monitor Chats..");
        const rawMonitorChats = _.get(result, "livechatAPI.fetchMonitorChats", []);
        // TODO: to remove and put in server side. Will affect chatsInteraction
        const fetchMonitorChatsArray = parseMonitorChats(rawMonitorChats);
        this.$store.commit("SET_MONITOR_CHATS_ARRAY", {
          monitorChatsArray: fetchMonitorChatsArray,
        });

        if (this.sessionId) {
          if (fetchMonitorChatsArray.length > 0) {
            this.$store.commit("SELECT_MONITOR_CHAT_BY_SESSION_ID", this.sessionId);
          } else {
            const pushNotifMessage = {
              title: "Cannot find selected chat",
              icon: "./img/icons/mstile-150x150.png",
            };
            pushNotification(pushNotifMessage, 2000, () => {
              window.focus();
            });
            _.set(this.sessionId, "");
            this.$router.replace("/livechat").catch(() => {});
          }
        }

        this.$store.commit("SET_FETCH_MONITOR_CHAT_LOADING", false);
        this.showMoreIsLoading = false;

        if (fetchMonitorChatsArray.length < this.monitorChatFilters.limit) {
          // to hide show more if no more chats available.
          this.hasNoMoreChats = true;
        }

        return fetchMonitorChatsArray;
      },
    },
  },
};
</script>

<style scoped lang="scss">
@import "../styles/tab.scss";
@import "../styles/chat-list.scss";
.list-item {
  place-items: normal;
}
</style>
